
import { Component, Prop, Vue } from 'vue-property-decorator';
import Close from '@/assets/icons/Close.svg';
import Markdown from '@/components/shared/Markdown.vue';

@Component({
  name: 'AssignmentSection',
  components: {
    Close,
    Markdown,
  },
})
export default class AssignmentSection extends Vue {
  @Prop({ default: false }) optional: boolean;
  @Prop({ default: false }) required: boolean;
  @Prop({ default: false }) dark: boolean;
  @Prop({ default: '' }) title: string;
  @Prop({ default: '' }) description: string;

  public closeSection() {
    this.$emit('onClose');
  }
}
