<template>
  <span v-html="data" />
</template>

<script>
import marked from 'marked';
import DOMPurify from 'dompurify';

export default {
  name: 'Markdown',
  props: {
    msg: {
      default: '',
      type: String,
    },
  },
  computed: {
    data() {
      const msg = DOMPurify.sanitize(this.msg);
      return marked(msg);
    },
  },
};
</script>
